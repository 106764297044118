export const scrollTop = (e: Event, element: string) => {
  if (element.startsWith("#")) {
    e.preventDefault();
    const el = document.getElementById(element.slice(1));
    if (el) {
      const headerOffset = 45;
      const elementPosition = el.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }
};
